



















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { getCounter, transferBindBank } from '@/api/index'
import router from '@/router'
import message from './components/message.vue'
import reMessage from './components/reMessage.vue'
import { AppModule } from '@/store/modules/app'
import { setMeta } from '@/utils/compatible'
import Cookies from 'js-cookie'

@Component({
  name: 'newTransfer',
  components: {
    message,
    reMessage
  }
})
export default class extends Vue {
  public isBind: boolean = false // 是否已绑卡
  public loading: boolean = true
  public disabled: boolean = true
  public orderInfo: any = {}
  public serviceText: any = {}
  public currentActionIndex = 0 // 1.发送银行列表，选择银行。2.发送银行code。
  public msgListOps: any[] = []
  public currentBankCode: string = ''
  public bankId: string = ''
  public transferPayerNo: string = '' // 输入的卡号
  public text: string = '' // 输入框内容
  public pollCount = 0 // 轮询次数
  public poll: any = null // 绑卡后 轮询订单状态

  @Watch('msgListOps')
  public onMsgListOpsChange() {
    this.scrollBottom()
  }

  // 移动到底部
  public scrollBottom() {
    this.$nextTick(() => {
      let targetbox: any = this.$refs['content']
      targetbox.scrollTo(0, 9999)
    })
  }

  // 选择银行
  public selBank(bank: any) {
    if (this.currentActionIndex >= 3) {
      this.sendMsg({
        type: 'service',
        text: this.serviceText.forbidden_choice_bank_card_message
      })
      return false
    }
    this.currentBankCode = bank.bank_code
    this.bankId = bank.id
    this.currentActionIndex = 2
    this.sendMsg({
      type: 'service',
      text: this.serviceText['second'],
      param: 'bank_name',
      data: bank.bank_name
    })
    this.disabled = false
  }

  // 发送银行卡号
  public send() {
    switch (this.currentActionIndex) {
      case 2:
        if (
          Cookies.get('currency') === 'VND' ||
          sessionStorage.getItem('currency') === 'VND'
        ) {
          this.currentActionIndex = 3
          this.transferPayerNo = this.text
          this.text = ''
          this.sendMsg({
            type: 'user',
            text: this.transferPayerNo
          })
          this.disabled = true
          this.transferBindBank()
        } else {
          if (this.text.length >= 10) {
            this.currentActionIndex = 3
            this.transferPayerNo = this.text
            this.text = ''
            this.sendMsg({
              type: 'user',
              text: this.transferPayerNo
            })
            this.disabled = true
            this.transferBindBank()
          } else {
            this.sendMsg({
              type: 'user',
              text: this.text
            })
            this.sendMsg({
              type: 'service',
              text: this.serviceText.invalid_card_message.replace(
                '{{length}}',
                '10'
              )
            })
          }
        }
        break
    }
  }

  // 绑卡
  public async transferBindBank() {
    await getCounter()

    const data: any = {
      transfer_payer_no: this.transferPayerNo,
      paybank: this.bankId
    }
    await transferBindBank(data).then((res: any) => {
      this.currentActionIndex = 4
      this.sendMsg({
        type: 'service',
        text: this.serviceText['third'],
        param: 'amount',
        data: this.orderInfo.amount
      })
      this.sendMsg({
        type: 'service',
        text: this.serviceText['account_info'],
        data: this.orderInfo.extra_param,
        isEnd: true
      })

      // 开启轮询
      this.startPoll()
    })
  }

  // 开启轮询
  public startPoll() {
    if (!this.poll) {
      this.poll = window.setInterval(() => {
        this.getCounter()
      }, 5000)
    }
  }

  public async getCounter() {
    try {
      await getCounter().then((res: any) => {
        if (!AppModule.chatInfo) {
          AppModule.SetChatInfo(res.extra_param.chat_message)
        }
        if (res.status === 0 && res.is_expired) {
          // 订单未支付 且订单过期
          if (this.poll) {
            window.clearInterval(this.poll)
          }
          router.replace({ name: 'error' })
        } else if (res.status === 1) {
          // 订单已支付
          if (this.poll) {
            window.clearInterval(this.poll)
          }
          router.replace({ name: 'success' })
        } else {
          // 订单未支付 且订单未过期
          if (this.pollCount === 0) {
            // 初始化
            if (res.extra_param.paybank) {
              // 已绑卡
              // 开启轮询
              this.startPoll()
              this.isBind = true
            }
            this.pollCount++
          }
          this.orderInfo = res
          this.serviceText = res.extra_param.chat_message
          this.currentActionIndex =
            this.currentActionIndex === 0 ? 1 : this.currentActionIndex
          if (this.msgListOps.length < 2) {
            this.initMsgList()
          }
        }
        this.loading = false
      })
    } catch (e) {
      if (this.poll) {
        window.clearInterval(this.poll)
        this.poll = null
      }
      this.loading = false
    }
  }

  // 初始化信息列表
  public initMsgList() {
    this.msgListOps = [
      {
        type: 'service',
        text: this.serviceText['first'],
        param: 'amount',
        data: this.orderInfo.amount
      },
      { type: 'bank', bankList: this.orderInfo.bank, selBank: this.selBank }
    ]
  }

  public sendMsg(msg: any) {
    this.msgListOps.push(msg)
  }

  created() {
    setMeta()
    AppModule.SetNavbar(false)
  }
  mounted() {
    this.pollCount = 0
    this.getCounter()
    if (
      Cookies.get('currency') === 'THB' ||
      sessionStorage.getItem('currency') === 'THB'
    ) {
      AppModule.SetLanguage('th')
    } else {
      AppModule.SetLanguage('en')
    }
  }

  beforeDestroy() {
    if (this.poll) {
      window.clearInterval(this.poll)
      this.poll = null
    }
  }
}
