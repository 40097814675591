





























































































































































































































import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'
import { getCounter, getQrCode } from '@/api/index'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { AppModule } from '@/store/modules/app'
import { clipboardSuccess } from '@/utils/clipboard'
import { currencyType } from '@/utils/constant'
import html2canvas from 'html2canvas'
import { LOADIPHLPAPI } from 'dns'

@Component({
  name: 'qrIndex',
  components: {}
})
export default class extends Vue {
  get isMobile() {
    return window.screen.availWidth <= 900 && window.screen.availWidth >= 310
  }
  public clipboardSuccess = clipboardSuccess
  public loading: boolean = true
  public expireTime: string = ''
  public orderInfo: any = {
    amount: '',
    bank: [],
    is_expired: false,
    ordernumber: '',
    status: 0
  }
  public config: any = {}
  public configdata: any = {}
  public time: any = '00:00'
  public timer: any = null
  public poll: any = null
  public currencyType: any = currencyType
  public retryCount: number = 3
  public videoUrl: any = ''
  public qrcodeWatermark: any = ''

  // 视频演示播放
  public videoShow: boolean = false
  public videoPlay() {
    this.videoShow = true
  }

  public closePlay() {
    this.videoShow = false
  }

  // 获取二维码
  public qrCode = {
    url: ''
  }
  public QRcode: any = ''
  public getQrcode = async () => {
    await getQrCode().then((res: any) => {
      this.qrCode.url = `data:image/png;base64,${res.qrcode}`
      this.anyAll()
    })
  }

  public async anyAll() {
    await this.getCounter()
    let canvas = document.createElement('canvas')
    let _this = this
    canvas.width = 320
    canvas.height = 320
    let myImage = new Image()
    myImage.src = this.qrCode.url // 需要添加文字的图片
    myImage.crossOrigin = 'Anonymous'
    let context: any = canvas.getContext('2d')
    myImage.onload = function () {
      context.drawImage(myImage, 0, 0, canvas.width, canvas.height)

      // context.fillStyle = 'rgba(255, 17, 17, 0.6)'
      context.fillStyle = 'red'
      context.rotate(-Math.PI / 6)
      let arr: any[] = []
      // let flag = _this.qrcodeWatermark.indexOf('\n')
      // if (flag === -1) {
      //   context.font = "20px Courier New";
      //   console.log(222);

      //   context.fillText(_this.qrcodeWatermark, -115, 230);  // 文字的内容和位置
      // } else {
      //   console.log(3333);

      //   context.font = "18px Courier New";
      //   arr = _this.qrcodeWatermark.split('\n')
      //   context.fillText(arr[0], -50, 160)
      //   context.fillText(arr[1], -100, 280)
      // }
      arr = _this.qrcodeWatermark.split('\n')
      let newArr = arr.filter(item => {
        return item !== ''
      })
      if (newArr.length === 1) {
        context.font = '25px Courier New'
        context.fillText(_this.qrcodeWatermark, -100, 230) // 文字的内容和位置
      } else {
        context.font = '22px Courier New'
        context.fillText(newArr[0], -50, 160)
        context.fillText(newArr[1], -100, 280)
      }
      let base64 = canvas.toDataURL('image/png') // "image/png" 这里注意一下
      _this.QRcode = base64
    }
  }

  public toImage() {
    let mian: any = document.getElementById('imageTofile')
    html2canvas(mian, {
      backgroundColor: 'white', //画出来的图片有白色的边框,不要可设置背景为透明色（null）
      useCORS: true, //支持图片跨域
      scale: 1 //设置放大的倍数
    }).then(canvas => {
      //截图用img元素承装，显示在页面的上
      let img = new Image()
      //如果你需要下载截图，可以使用a标签进行下载
      let a = document.createElement('a')
      a.href = canvas.toDataURL('image/pdf')
      a.download = `${this.orderInfo.ordernumber}`
      a.click()
    })
  }

  public toImageH5() {
    let mian: any = document.getElementById('main')
    html2canvas(mian, {
      backgroundColor: 'white', //画出来的图片有白色的边框,不要可设置背景为透明色（null）
      useCORS: true, //支持图片跨域
      scale: 1 //设置放大的倍数
    }).then(canvas => {
      //截图用img元素承装，显示在页面的上
      let img = new Image()
      //如果你需要下载截图，可以使用a标签进行下载
      let a = document.createElement('a')
      a.href = canvas.toDataURL('image/pdf')
      a.download = `${this.orderInfo.ordernumber}`
      a.click()
    })
  }

  // 下载图片
  public downloadImage(url) {
    // 如果是在网页中可以直接创建一个 a 标签直接下载
    let a = document.createElement('a')
    a.href = url
    a.download = `${this.orderInfo.ordernumber}`
    a.click()
  }

  public async getCounter() {
    try {
      await getCounter().then((res: any) => {
        this.orderInfo = res
        this.videoUrl = this.orderInfo.video_url
        if (!AppModule.chatInfo) {
          this.config = res.extra_param.config
          this.configdata = res.extra_param
          this.qrcodeWatermark = res.extra_param.config.qrcode_watermark

          AppModule.SetChatInfo(res.extra_param.config)
          AppModule.SetChatInfo(res.extra_param)
        }
        // 取最小时间
        const time = res.extra_param.expire_time
          ? res.extra_param.expire_time > res.expire_time
            ? res.expire_time
            : res.extra_param.expire_time
          : res.expire_time
        // 根据语言获取时区
        const timezone =
          this.$route.query['language'] === 'vi' ||
          this.$route.query['language'] === 'th' ||
          this.$route.query['language'] === 'id'
            ? 'Asia/Bangkok'
            : ''
        this.expireTime = timezone
          ? momentTimezone
              .tz(time * 1000, timezone)
              .format('YYYY-MM-DD HH:mm:ss')
          : moment(time * 1000).format('YYYY-MM-DD HH:mm:ss')
        if (!res.is_expired && res.status === 0) {
          // 订单未过期 且 未支付
          if (!this.poll) {
            // 轮询未开始，则开始轮询
            this.poll = window.setInterval(() => {
              this.getCounter()
            }, 5000)
            const diffTime = res.current_time * 1000 - Date.now()
            this.timer = window.setInterval(async () => {
              let start = momentTimezone.tz(Date.now() + diffTime, timezone) //获取开始时间
              let end = momentTimezone.tz(time * 1000, timezone) //结束时间
              let diff = end.diff(start) //时间差
              let minutes: any = moment.duration(diff).minutes()
              minutes = minutes < 10 ? '0' + minutes : minutes
              let seconds: any = moment.duration(diff).seconds()
              seconds = seconds < 10 ? '0' + seconds : seconds
              if (diff > 0) {
                this.time = minutes + ':' + seconds
              } else {
                window.clearInterval(this.timer)
                window.clearInterval(this.poll)
                router.replace({ name: 'error' })
              }
            }, 1000)
          }
        } else if (res.is_expired) {
          // 订单已过期
          if (this.poll) {
            // 轮询开启则关闭
            window.clearInterval(this.poll)
            window.clearInterval(this.timer)
          }
          router.replace({ name: 'error' })
        } else if (res.status === 1) {
          // 已支付
          if (this.poll) {
            // 轮询开启则关闭
            window.clearInterval(this.poll)
            window.clearInterval(this.timer)
          }
          router.replace({ name: 'success' })
        }
        this.loading = false
      })
    } catch (e) {
      if (!this.poll) {
        if (this.retryCount-- > 0) {
          this.getCounter()
        } else {
          this.$confirm(`Do you want to try again?`, 'The Request Timeout')
            .then(() => {
              this.retryCount = 3
              this.getCounter()
            })
            .catch(() => {
              router.replace({ name: 'error' })
            })
        }
      }
      throw new Error(`${this.$route.path}Counter接口出错`)
    }
  }

  created() {
    this.getQrcode()
    // this.getCounter()
  }

  destroyed() {
    if (this.poll || this.timer) {
      window.clearInterval(this.poll)
      window.clearInterval(this.timer)
    }
  }
}
