













import { AppModule } from '@/store/modules/app'
import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'jumpPage',
  components: {}
})
export default class extends Vue {
  private text = AppModule.chatInfo?.expire_message
    ? AppModule.chatInfo.expire_message
    : ''
  created() {
    AppModule.SetNavbar(true)
  }
}
