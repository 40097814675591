












































































































































































import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'
import { getCounter, getIndonesiaQr, tryGetIndonesiaQr } from '@/api/index'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { clipboardSuccess } from '@/utils/clipboard'
import { currencyType } from '@/utils/constant'
@Component({
  name: 'qrIndex',
  components: {}
})
export default class extends Vue {
  get isMobile() {
    return window.screen.availWidth <= 475 && window.screen.availWidth >= 310
  }
  public clipboardSuccess = clipboardSuccess
  public loading: boolean = true
  public isBindBank: boolean = true
  public qrCount: number = 0
  public qrcode: string = ''
  public expireTime: string = ''
  public percentage: number = 0
  public orderInfo: any = {
    amount: '',
    bank: [],
    is_expired: false,
    ordernumber: '',
    status: 0
  }
  public config: any = {}
  public configdata: any = {}
  public time: any = '00:00'
  public timer: any = null
  public poll: any = null
  public currencyType: any = currencyType
  public retryCount: number = 3

  public async getCounter() {
    try {
      await getCounter().then((res: any) => {
        this.orderInfo = res
        // 取最小时间
        const time = res.extra_param.expire_time
          ? res.extra_param.expire_time > res.expire_time
            ? res.expire_time
            : res.extra_param.expire_time
          : res.expire_time
        // 根据语言获取时区
        const timezone =
          this.$route.query['language'] === 'vi' ||
          this.$route.query['language'] === 'th' ||
          this.$route.query['language'] === 'id'
            ? 'Asia/Bangkok'
            : ''
        this.expireTime = timezone
          ? momentTimezone
              .tz(time * 1000, timezone)
              .format('YYYY-MM-DD HH:mm:ss')
          : moment(time * 1000).format('YYYY-MM-DD HH:mm:ss')
        if (!this.timer) {
          const diffTime = res.current_time * 1000 - Date.now()
          this.timer = window.setInterval(async () => {
            let start = momentTimezone.tz(Date.now() + diffTime, timezone) //获取开始时间
            let end = momentTimezone.tz(time * 1000, timezone) //结束时间
            let diff = end.diff(start) //时间差
            let minutes: any = moment.duration(diff).minutes()
            minutes = minutes < 10 ? '0' + minutes : minutes
            let seconds: any = moment.duration(diff).seconds()
            seconds = seconds < 10 ? '0' + seconds : seconds
            if (diff > 0) {
              this.time = minutes + ':' + seconds
            } else {
              clearInterval(this.timer)
              clearInterval(this.poll)
              router.replace({ name: 'error' })
            }
          }, 1000)
        }

        if (!res.is_expired && res.status === 0) {
          // 订单未过期 且 未支付
          if (!this.poll) {
            // 轮询未开始，则开始轮询
            this.poll = setInterval(() => {
              this.getCounter()
              if (!this.qrcode && this.qrCount < 10) {
                this.getIndonesiaQr()
              }
            }, 2000)
          }
        } else if (res.is_expired) {
          // 订单已过期
          if (this.poll) {
            // 轮询开启则关闭
            clearInterval(this.poll)
            clearInterval(this.timer)
          }
          router.replace({ name: 'error' })
        } else if (res.status === 1) {
          // 已支付
          if (this.poll) {
            // 轮询开启则关闭
            clearInterval(this.poll)
            clearInterval(this.timer)
          }
          router.replace({ name: 'success' })
        }
      })
      if (this.loading) this.loading = false
    } catch (err) {
      let e: any = err
      if (!this.poll) {
        if (this.retryCount-- > 0) {
          this.getCounter()
        } else {
          this.$confirm(`Do you want to try again?`, 'The Request Timeout')
            .then(() => {
              this.retryCount = 3
              this.getCounter()
            })
            .catch(() => {
              router.replace({ name: 'error' })
            })
        }
      }
      throw new Error(`${this.$route.path}Counter接口出错${e.toString()}`)
    }
  }

  public async getIndonesiaQr() {
    try {
      this.qrCount++
      await getIndonesiaQr().then((res: any) => {
        let p = (this.percentage += Math.round(Math.random() * 5))
        this.percentage = p > 99 ? 99 : p
        this.qrcode = res.qrcode ? res.qrcode : ''
      })
    } catch (e) {}
  }

  public async tryGetQr() {
    try {
      await tryGetIndonesiaQr().then(() => {
        this.percentage = 0
        this.qrCount = 0
      })
    } catch (e) {}
  }
  mounted() {
    this.getCounter()
    this.getIndonesiaQr()
  }

  destroyed() {
    if (this.poll || this.timer) {
      clearInterval(this.poll)
      clearInterval(this.timer)
    }
  }
}
