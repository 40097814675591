










































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'
import { getCounter } from '@/api/index'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { AppModule } from '@/store/modules/app'
import { clipboardSuccess } from '@/utils/clipboard'
import { currencyType } from '@/utils/constant'
import { log } from 'console'
@Component({
  name: 'KBankTransfer',
  components: {}
})
export default class extends Vue {
  get isMobile () {
    return window.screen.availWidth <= 475 && window.screen.availWidth >= 310
  }
  private clipboardSuccess = clipboardSuccess
  private loading: boolean = true
  private isBindBank: boolean = true
  private expireTime: string = ''
  private orderInfo: any = {}
  private time: any = '00:00'
  private timer: any = null
  private poll: any = null
  private currencyType: any = currencyType
  private accountArr: any = []
  private newAccoutnArr: any = []
  private serviceText: any = {}
  private spliceStr: any = []

  private payAmount = 0

  private results = ''
  private async getCounter () {
    try {
      await getCounter().then((res: any) => {
        this.orderInfo = res
        this.newAccoutnArr = res.extra_param.chat_message.account_info
        this.payAmount =
          Number(this.orderInfo.amount) - Number(this.orderInfo.prepay)
        this.results = this.payAmount.toFixed(2)
        this.spliceStr = this.newAccoutnArr.map((v: any) => {
          return v.split('{', 1).toString()
        })

        // 取最小时间
        const time = res.expire_time
          ? res.expire_time > res.expire_time
            ? res.expire_time
            : res.expire_time
          : res.expire_time
        // 根据语言获取时区
        const timezone =
          this.$route.query['language'] === 'vi' ||
          this.$route.query['language'] === 'th' ||
          this.$route.query['language'] === 'id'
            ? 'Asia/Bangkok'
            : ''
        this.expireTime = timezone
          ? momentTimezone
              .tz(time * 1000, timezone)
              .format('YYYY-MM-DD HH:mm:ss')
          : moment(time * 1000).format('YYYY-MM-DD HH:mm:ss')
        if (!res.is_expired && res.status === 0) {
          // 订单未过期 且 未支付
          if (!this.poll) {
            // 轮询未开始，则开始轮询
            this.poll = setInterval(() => {
              this.getCounter()
            }, 5000)
            const diffTime = res.current_time * 1000 - Date.now()
            this.timer = window.setInterval(async () => {
              let start = momentTimezone.tz(Date.now() + diffTime, timezone) //获取开始时间
              let end = momentTimezone.tz(time * 1000, timezone) //结束时间
              let diff = end.diff(start) //时间差
              let minutes: any = moment.duration(diff).minutes()
              minutes = minutes < 10 ? '0' + minutes : minutes
              let seconds: any = moment.duration(diff).seconds()
              seconds = seconds < 10 ? '0' + seconds : seconds
              if (diff > 0) {
                this.time = minutes + ':' + seconds
              } else {
                clearInterval(this.timer)
                clearInterval(this.poll)
                router.replace({ name: 'error' })
              }
            }, 1000)
          }
        } else if (res.is_expired) {
          // 订单已过期
          if (this.poll) {
            // 轮询开启则关闭
            clearInterval(this.poll)
            clearInterval(this.timer)
          }
          router.replace({ name: 'error' })
        } else if (res.status === 1) {
          // 已支付
          if (this.poll) {
            // 轮询开启则关闭
            clearInterval(this.poll)
            clearInterval(this.timer)
          }
          router.replace({ name: 'success' })
        }
        this.loading = false
      })
    } catch (e) {
      if (this.poll) {
        clearInterval(this.poll)
      }
      this.loading = false
    }
  }

  mounted () {
    this.getCounter()
  }

  destroyed () {
    if (this.poll || this.timer) {
      clearInterval(this.poll)
      clearInterval(this.timer)
    }
  }
}
