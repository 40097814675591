import {} from './validate'

const validatePassword = (rule: any, value: string, callback: Function) => {
  if (value.length < 6) {
    callback(new Error('The password can not be less than 6 digits'))
  } else {
    callback()
  }
}

const validatePassword2 = (rule: any, value: string, callback: Function) => {
  if (!value) {
    callback()
  } else {
    if (value.length < 6) {
      callback(new Error('The password can not be less than 6 digits'))
    } else {
      callback()
    }
  }
}

const upiRules1 = (rule: any, value: string, callback: Function) => {
  if (!value) {
    callback(new Error('Please enter the correct one UPI ID'))
  } else if (value === '') {
    callback()
  } else {
    if (value.indexOf('@') == -1) {
      callback(new Error('UPI ID wrong, please input again'))
    } else {
      callback()
    }
  }
}

export const adminRules = {
  username: [
    { required: true, message: 'Please enter the Username', trigger: 'blur' }
  ],
  password: [
    {
      required: true,
      message: 'Please enter the Password',
      validator: validatePassword,
      trigger: 'blur'
    }
  ],
  email: [
    {
      required: true,
      message: 'Please enter your email address',
      trigger: 'blur'
    },
    {
      type: 'email',
      message: 'Please enter the correct email address',
      trigger: ['blur', 'change']
    }
  ]
}

export const setAdminRules = {
  username: [
    { required: true, message: 'Please enter the Username', trigger: 'blur' }
  ],
  password: [{ validator: validatePassword2, trigger: 'blur' }],
  email: [
    {
      required: true,
      message: 'Please enter your email address',
      trigger: 'blur'
    },
    {
      type: 'email',
      message: 'Please enter the correct email address',
      trigger: ['blur', 'change']
    }
  ]
}

export const productAccountBasicRules = {
  account_name: [
    {
      required: true,
      message: 'Please enter the Account Name',
      trigger: 'blur'
    }
  ],
  account_no: [
    { required: true, message: 'Please enter the Account No', trigger: 'blur' }
  ],
  account_type: [
    { required: true, message: 'Please select Account Type', trigger: 'blur' }
  ],
  status: [{ required: true, message: 'Please select status', trigger: 'blur' }]
}

export const merchantBasicRules = {
  name: [
    {
      required: true,
      message: 'Please enter the Merchant Name',
      trigger: 'blur'
    }
  ],
  email: [
    {
      required: true,
      message: 'Please enter your email address',
      trigger: 'blur'
    },
    {
      type: 'email',
      message: 'Please enter the correct email address',
      trigger: ['blur', 'change']
    }
  ],
  password: [
    { required: true, message: 'Please set your password', trigger: 'blur' }
  ],
  pin: [
    {
      required: true,
      message: 'Please set the withdrawal password',
      trigger: 'blur'
    }
  ],
  status: [{ required: true, message: 'Please select status', trigger: 'blur' }]
}

export const merchantProductRules = {
  currency: [
    { required: true, message: 'Please select Currency', trigger: 'blur' }
  ],
  product_type: [
    { required: true, message: 'Please select product type', trigger: 'blur' }
  ],
  product_name: [
    { required: true, message: 'Please select Product', trigger: 'blur' }
  ],
  status: [{ required: true, message: 'Please select status', trigger: 'blur' }]
}

export const merchantAgentProductRules = {
  currency: [
    { required: true, message: 'Please select Currency', trigger: 'blur' }
  ],
  product_type: [
    { required: true, message: 'Please select product type', trigger: 'blur' }
  ],
  product_name: [
    { required: true, message: 'Please select Product', trigger: 'blur' }
  ]
}

export const descendantsRules = {
  start_time: [
    { required: true, message: 'Please select Start time', trigger: 'blur' }
  ],
  end_time: [
    { required: true, message: 'Please select End time', trigger: 'blur' }
  ]
}

export const bankAccountRules = {
  currency: [
    { required: true, message: 'Please select Currency', trigger: 'blur' }
  ],
  status: [{ required: true, message: 'Please select status', trigger: 'blur' }]
}

export const merchantBananceRules = {
  currency: [
    { required: true, message: 'Please select Currency', trigger: 'blur' }
  ],
  amount: [{ required: true, message: 'Please input amount', trigger: 'blur' }],
  remarks: [
    { required: true, message: 'Please input remarks', trigger: 'blur' }
  ],
  in_or_out: [
    { required: true, message: 'Please select Direction', trigger: 'blur' }
  ]
}

export const setOrderStatusRules = {
  remarks: [
    { required: true, message: 'Please input remarks', trigger: 'blur' }
  ]
}

export const upiRules = {
  upi: [
    {
      // required: true,
      // message: 'Please enter the correct one UPI ID',
      validator: upiRules1,
      trigger: 'blur'
    }
  ]
}

export const testRules = {
  test_text: [{ required: true, message: 'Please input', trigger: 'blur' }],
  test_password: [{ required: true, message: 'Please input', trigger: 'blur' }],
  test_textarea: [{ required: true, message: 'Please input', trigger: 'blur' }],
  test_radio: [{ required: true, message: 'Please choice', trigger: 'blur' }],
  test_checkbox: [
    { required: true, message: 'Please choice', trigger: 'blur' }
  ],
  test_select: [{ required: true, message: 'Please select', trigger: 'blur' }]
}
