




































































































































































































































import { Component, Vue } from "vue-property-decorator";
import router from "@/router";
import { getCounter } from "@/api/index";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { AppModule } from "@/store/modules/app";
import { currencyType } from "@/utils/constant";
import { upiRules } from "@/utils/rules";

@Component({
  name: "qrIndex",
  components: {},
})
export default class extends Vue {
  get isMobile() {
    return window.screen.availWidth <= 475 && window.screen.availWidth >= 310;
  }
  private loading: boolean = true;
  private verifyLoading: boolean = false;
  private qrcode: string = "";
  private expireTime: string = "";
  private orderInfo: any = {
    amount: "",
    bank: [],
    is_expired: false,
    ordernumber: "",
    status: 0,
  };
  private config: any = {};
  private configdata: any = {};
  private time: any = "00:00";
  private timer: any = null;
  private poll: any = null;
  private currencyType: any = currencyType;
  private showQR: boolean = false;
  private isShow: boolean = false;

  private form: any = {
    upi: "",
  };

  private rules = upiRules;

  private get upiArr() {
    this.isShow = true;
    let upiArr = [
      "apl",
      "abfspay",
      "axisb",
      "axl",
      "barodampay",
      "centralbank",
      "cbin",
      "cboi",
      "cnrb",
      "ezeepay",
      "eazypay",
      "fbl",
      "hdfcbank",
      "hsbc",
      "hdfcbankjd",
      "hsbc",
      "Idbi",
      "imobile",
      "icici",
      "idbibank",
      "ikwik",
      "ibl",
      "jupiteraxis",
      "kotak",
      "kaypay",
      "kmb",
      "kmbl",
      "mahb",
      "myicici",
      "okicici",
      "okhdfcbank",
      "okaxis",
      "oksbi",
      "paytm",
      "pockets",
      "payzapp",
      "pnb",
      "pingpay",
      "rajgovhdfcbank",
      "Sbi",
      "timecosmos",
      "Ubi",
      "united",
      "utbi",
      "waaxis",
      "wahdfcbank",
      "wasbi",
      "Yesbank",
      "yesbankltd",
      "ybl",
      "yesbank",
    ];
    if (this.form.upi.indexOf("@") > -1) {
      if (this.form.upi.length > this.form.upi.indexOf("@")) {
        let str = this.form.upi.slice(
          this.form.upi.indexOf("@") + 1,
          this.form.upi.length + 1
        );
        return upiArr.map((item) => {
          if (
            item.toLowerCase().indexOf(str.toLowerCase()) === 0 ||
            item.toUpperCase().indexOf(str.toUpperCase()) === 0
          ) {
            let option =
              this.form.upi.slice(0, this.form.upi.indexOf("@") + 1) + item;
            return option;
          }
        });
      }
    }
    if (!this.form.upi) {
      return [];
    }
  }

  private setInput(mail) {
    this.form.upi = mail;
  }

  private blur() {
    setTimeout(() => {
      this.isShow = false;
    }, 100);
  }

  private showQr: boolean = false;
  private retryCount: number = 3;
  private videoUrl: any = "";
  private jumpUrl: any = "";
  private isJumping: boolean = false; // 是否正在跳转

  private jump() {
    window.open(this.jumpUrl);
  }
  private jumpAuto() {
    if (this.isJumping) {
      return;
    } else {
      this.isJumping = true;
      window.open(this.jumpUrl);
    }
  }

  private copyOrder() {
    Vue.prototype.$message({
      message: "Copy successfully",
      type: "success",
      duration: 1500,
    });
  }
  private copyUpi() {
    Vue.prototype.$message({
      message: "Copy successfully",
      type: "success",
      duration: 1500,
    });
  }
  private loopCount: number = 0; // 轮询次数
  private async getCounter() {
    try {
      await getCounter().then((res: any) => {
        this.orderInfo = res;
        this.videoUrl = this.orderInfo.video_url;
        this.jumpUrl = res.extra_param.qrcode_jumpurl;
        if (this.loopCount === 0) {
          setTimeout(() => {
            this.jumpAuto();
          }, 1000);
        }
        if (!AppModule.chatInfo) {
          this.config = res.extra_param.config;
          this.configdata = res.extra_param;
          AppModule.SetChatInfo(res.extra_param.config);
          AppModule.SetChatInfo(res.extra_param);
        }
        this.qrcode = res.extra_param.qrcode ? res.extra_param.qrcode : "";
        // 取最小时间
        const time = res.extra_param.expire_time
          ? res.extra_param.expire_time > res.expire_time
            ? res.expire_time
            : res.extra_param.expire_time
          : res.expire_time;
        // 根据语言获取时区
        const timezone =
          this.$route.query["language"] === "vi" ||
          this.$route.query["language"] === "th" ||
          this.$route.query["language"] === "id"
            ? "Asia/Bangkok"
            : "";
        this.expireTime = timezone
          ? momentTimezone
              .tz(time * 1000, timezone)
              .format("YYYY-MM-DD HH:mm:ss")
          : moment(time * 1000).format("YYYY-MM-DD HH:mm:ss");
        if (!res.is_expired && res.status === 0) {
          // 订单未过期 且 未支付
          if (!this.poll) {
            // 轮询未开始，则开始轮询
            this.poll = setInterval(() => {
              this.getCounter();
              this.loopCount++;
            }, 5000);
            this.timer = setInterval(() => {
              let start = moment(new Date()); //获取开始时间
              let end = moment(res.expire_time * 1000); //结束时间
              let diff = end.diff(start); //时间差
              let minutes: any = moment.duration(diff).minutes();
              minutes = minutes < 10 ? "0" + minutes : minutes;
              let seconds: any = moment.duration(diff).seconds();
              seconds = seconds < 10 ? "0" + seconds : seconds;
              if (diff > 0) {
                this.time = minutes + ":" + seconds;
              } else {
                clearInterval(this.timer);
                clearInterval(this.poll);
                router.replace({ name: "error" });
              }
            }, 1000);
          }
        } else if (res.is_expired) {
          // 订单已过期
          if (this.poll) {
            // 轮询开启则关闭
            clearInterval(this.poll);
            clearInterval(this.timer);
          }
          router.replace({ name: "error" });
        } else if (res.status === 1) {
          // 已支付
          if (this.poll) {
            // 轮询开启则关闭
            clearInterval(this.poll);
            clearInterval(this.timer);
          }
          router.replace({ name: "success" });
        }
      });
      this.loading = false;
    } catch (e) {
      if (!this.poll) {
        if (this.retryCount-- > 0) {
          this.getCounter();
        } else {
          this.$confirm(`Do you want to try again?`, "The Request Timeout")
            .then(() => {
              this.retryCount = 3;
              this.getCounter();
            })
            .catch(() => {
              router.replace({ name: "error" });
            });
        }
      }
      throw new Error(`${this.$route.path}Counter接口出错${e.toString()}`);
    }
  }

  mounted() {
    this.getCounter();
  }
}
