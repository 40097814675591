














import router from '@/router'
import { AppModule } from '@/store/modules/app'
import { Component, Vue } from 'vue-property-decorator'
import Cookies from 'js-cookie'

@Component({
  name: 'success',
  components: {}
})
export default class extends Vue {
  public return_url: any = ''
  public timer: any = null
  public time = 5
  public successText = AppModule.chatInfo?.payment_success_message
    ? AppModule.chatInfo.payment_success_message
    : ''
  get jumpText() {
    return AppModule.chatInfo?.jump_message
      ? AppModule.chatInfo.jump_message.replace('{{countdown}}', this.time)
      : ''
  }
  created() {
    AppModule.SetNavbar(true)
    this.return_url =  Cookies.get('return_url')||sessionStorage.getItem('return_url')
    if (this.return_url) {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (--this.time === 0) {
            clearInterval(this.timer)
            window.location.href = this.return_url
          }
        }, 1000)
      }
    }
  }
}
