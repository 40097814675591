
















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { clipboardSuccess } from '@/utils/clipboard'
import Cookies from 'js-cookie'

// const fieldMap = {
//   '{{amount}}': 'amount',
//   '{{bank_code}}': 'bank_code',
//   '{{bank_name}}': 'bank_name',
//   '{{ordernumber}}': 'ordernumber',
//   '{{card_no}}': ['extra_param', 'card_no'],
//   '{{card_name}}': ['extra_param', 'card_name'],
// }

@Component({
  name: 'message',
  components: {}
})
export default class extends Vue {
  @Prop({ required: true }) type!: string // 消息发送方
  @Prop({ default: '' }) text!: string // 消息内容
  @Prop() bankList!: any[] // 银行列表
  @Prop({ default: '' }) param!: string // 替换的参数名
  @Prop({ default: null }) data!: any // 替换的数据
  @Prop({ default: false }) isCopy!: boolean // 复制
  // @Prop({default: {}}) info!: any           // 支付所有相关信息
  @Watch('data', { immediate: true })
  public onDataChange() {
    if (this.text && this.param) {
      this.myText = this.text
      this.myText = this.myText.replace('{{' + this.param + '}}', this.data)
      if (this.param === 'bank_code' || this.param === 'bank_name') {
        // ts不支持replaceAll
        this.myText = this.myText.replace('{{bank_code}}', this.data)
        this.myText = this.myText.replace('{{bank_name}}', this.data)
      }
    }
  }

  public myText: string = ''
  public clipboardSuccess = clipboardSuccess
  public currentBankIndex = -1

  public getLogo(bank: any) {
    try {
      return require(`@/assets/bank-logo/${Cookies.get('currency')}/${
        bank.bank_code
      }.png`)
    } catch (e) {
      return bank.logo
    }
  }

  public selBank(bank: any, index: number) {
    this.currentBankIndex = index
    this.$emit('selBank', bank)
  }

  created() {}
}
