export const statusList: any[] = [
  { type: 'danger', text: 'Disable' },
  { type: 'success', text: 'Active' }
]

export const notifiedStatusList: any[] = [
  { type: 'danger', text: 'Not Notified' },
  { type: 'success', text: 'Notified' }
]

export const orderStatusList: any[] = [
  { type: 'info', text: 'non-payment' },
  { type: 'success', text: 'payment success' },
  { type: 'warning', text: 'await audit' },
  { type: 'primary', text: 'Dispensing' },
  { type: 'danger', text: 'order failed' }
]
export const currencyType: any = {
  THB: '฿',
  VND: '₫',
  BRL: 'R$',
  IDR: 'Rp',
  INR: '₹',
  USDT: 'USDT'
}
