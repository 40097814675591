
























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { clipboardSuccess } from '@/utils/clipboard' 

@Component({
  name: 'message',
  components: {
  }
})
export default class extends Vue {
  @Prop({required: true}) type!: string     // 消息发送方
  @Prop({default: []}) text!: string[]        // 消息内容
  @Prop() bankList!: any[]                  // 银行列表
  @Prop({default: null}) data!: any         // 替换的数据
  @Prop({default: false}) isCopy!: boolean  // 复制

  private reg = /\{\{(.*)\}\}/
  private list: any[] = []
  @Watch('data', {immediate: true})
  private onDataChange() {
    if (this.text) {
      this.list.length = 0
      this.text.forEach((item: string) => {
        if (this.reg.test(item)) {
          const param = RegExp.$1
          if (param === 'card_bank') {
            const str = item.replace('{{'+ param + '}}', '')
            this.list.push({type: 'obj',text: str, bank_code: this.data[param]['bank_code'], logo: this.getLogo(this.data[param])})
          } else {
            const str = item.replace('{{'+ param + '}}' , this.data[param])
            this.list.push({type: 'string',text: str, param: this.data[param]})
          }
        }
      })
    }
  }
  private clipboardSuccess = clipboardSuccess

  private getLogo(bank: any) {
    try {
      return require('@/assets/bank-logo/' + bank.bank_code + '.png')
    } catch(e) {
      return bank.link
    }
  }
  created() {
  }
}
