



























































































import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'
import { getCounter, getQrCode } from '@/api/index'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { AppModule } from '@/store/modules/app'
import { clipboardSuccess } from '@/utils/clipboard'
import { currencyType } from '@/utils/constant'

@Component({
  name: 'qrPC',
  components: {}
})
export default class extends Vue {
  get isMobile () {
    return window.screen.availWidth <= 475 && window.screen.availWidth >= 310
  }
  private clipboardSuccess = clipboardSuccess
  private loading: boolean = true
  private expireTime: string = ''
  private orderInfo: any = {
    amount: '',
    bank: [],
    is_expired: false,
    ordernumber: '',
    status: 0
  }
  private config: any = {}
  private configdata: any = {}
  private time: any = '00:00'
  private timer: any = null
  private poll: any = null
  private currencyType: any = currencyType
  private retryCount: number = 3
  private videoUrl: any = ''
  private qrcodeWatermark: any = ''

  // 视频演示播放
  private videoShow: boolean = false
  private videoPlay () {
    this.videoShow = true
  }

  private closePlay () {
    this.videoShow = false
  }

  // 获取二维码
  private qrCode = {
    url: ''
  }
  private getQrcode = async () => {
    await getQrCode().then((res: any) => {
      this.qrCode.url = `data:image/png;base64,${res.qrcode}`
    })
  }

  private async getCounter () {
    try {
      await getCounter().then((res: any) => {
        this.orderInfo = res
        this.videoUrl = this.orderInfo.video_url
        if (!AppModule.chatInfo) {
          this.config = res.extra_param.config
          this.configdata = res.extra_param
          this.qrcodeWatermark = res.extra_param.config.qrcode_watermark

          AppModule.SetChatInfo(res.extra_param.config)
          AppModule.SetChatInfo(res.extra_param)
        }
        // 取最小时间
        const time = res.extra_param.expire_time
          ? res.extra_param.expire_time > res.expire_time
            ? res.expire_time
            : res.extra_param.expire_time
          : res.expire_time
        // 根据语言获取时区
        const timezone =
          this.$route.query['language'] === 'vi' ||
          this.$route.query['language'] === 'th' ||
          this.$route.query['language'] === 'id'
            ? 'Asia/Bangkok'
            : ''
        this.expireTime = timezone
          ? momentTimezone
              .tz(time * 1000, timezone)
              .format('YYYY-MM-DD HH:mm:ss')
          : moment(time * 1000).format('YYYY-MM-DD HH:mm:ss')
        if (!res.is_expired && res.status === 0) {
          // 订单未过期 且 未支付
          if (!this.poll) {
            // 轮询未开始，则开始轮询
            this.poll = window.setInterval(() => {
              this.getCounter()
            }, 5000)
            const diffTime = res.current_time * 1000 - Date.now()
            this.timer = window.setInterval(async () => {
              let start = momentTimezone.tz(Date.now() + diffTime, timezone) //获取开始时间
              let end = momentTimezone.tz(time * 1000, timezone) //结束时间
              let diff = end.diff(start) //时间差
              let minutes: any = moment.duration(diff).minutes()
              minutes = minutes < 10 ? '0' + minutes : minutes
              let seconds: any = moment.duration(diff).seconds()
              seconds = seconds < 10 ? '0' + seconds : seconds
              if (diff > 0) {
                this.time = minutes + ':' + seconds
              } else {
                window.clearInterval(this.timer)
                window.clearInterval(this.poll)
                router.replace({ name: 'error' })
              }
            }, 1000)
          }
        } else if (res.is_expired) {
          // 订单已过期
          if (this.poll) {
            // 轮询开启则关闭
            window.clearInterval(this.poll)
            window.clearInterval(this.timer)
          }
          router.replace({ name: 'error' })
        } else if (res.status === 1) {
          // 已支付
          if (this.poll) {
            // 轮询开启则关闭
            window.clearInterval(this.poll)
            window.clearInterval(this.timer)
          }
          router.replace({ name: 'success' })
        }
        this.loading = false
      })
    } catch (e) {
      if (!this.poll) {
        if (this.retryCount-- > 0) {
          this.getCounter()
        } else {
          this.$confirm(`Do you want to try again?`, 'The Request Timeout')
            .then(() => {
              this.retryCount = 3
              this.getCounter()
            })
            .catch(() => {
              router.replace({ name: 'error' })
            })
        }
      }
      throw new Error(`${this.$route.path}Counter接口出错`)
    }
  }

  created () {
    this.getQrcode()
    this.getCounter()
  }

  destroyed () {
    if (this.poll || this.timer) {
      window.clearInterval(this.poll)
      window.clearInterval(this.timer)
    }
  }
}
