














































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'
import { getCounter, bindUpi, bindUtr } from '@/api/index'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { AppModule } from '@/store/modules/app'
// import { clipboardSuccess } from '@/utils/clipboard'
import { currencyType } from '@/utils/constant'
import { Form as ElForm } from 'element-ui'
import { upiRules } from '@/utils/rules'
import Cookies from 'js-cookie'

@Component({
  name: 'qrIndex',
  components: {}
})
export default class extends Vue {
  get isMobile() {
    return window.screen.availWidth <= 475 && window.screen.availWidth >= 310
  }
  // public clipboardSuccess = clipboardSuccess
  public loading: boolean = true
  public verifyLoading: boolean = false
  public qrcode: string = ''
  public expireTime: string = ''
  public orderInfo: any = {
    amount: '',
    bank: [],
    is_expired: false,
    ordernumber: '',
    status: 0
  }
  public config: any = {}
  public configdata: any = {}
  public time: any = '00:00'
  public timer: any = null
  public poll: any = null
  public currencyType: any = currencyType
  public showQR: boolean = false
  public isShow: boolean = false

  public form: any = {
    upi: ''
  }

  public rules = upiRules

  public get upiArr() {
    this.isShow = true
    let upiArr = [
      'apl',
      'abfspay',
      'axisb',
      'axl',
      'barodampay',
      'centralbank',
      'cbin',
      'cboi',
      'cnrb',
      'ezeepay',
      'eazypay',
      'fbl',
      'hdfcbank',
      'hsbc',
      'hdfcbankjd',
      'hsbc',
      'Idbi',
      'imobile',
      'icici',
      'idbibank',
      'ikwik',
      'ibl',
      'jupiteraxis',
      'kotak',
      'kaypay',
      'kmb',
      'kmbl',
      'mahb',
      'myicici',
      'okicici',
      'okhdfcbank',
      'okaxis',
      'oksbi',
      'paytm',
      'pockets',
      'payzapp',
      'pnb',
      'pingpay',
      'rajgovhdfcbank',
      'Sbi',
      'timecosmos',
      'Ubi',
      'united',
      'utbi',
      'waaxis',
      'wahdfcbank',
      'wasbi',
      'Yesbank',
      'yesbankltd',
      'ybl',
      'yesbank'
    ]
    if (this.form.upi.indexOf('@') > -1) {
      if (this.form.upi.length > this.form.upi.indexOf('@')) {
        let str = this.form.upi.slice(
          this.form.upi.indexOf('@') + 1,
          this.form.upi.length + 1
        )
        return upiArr.map(item => {
          if (
            item.toLowerCase().indexOf(str.toLowerCase()) === 0 ||
            item.toUpperCase().indexOf(str.toUpperCase()) === 0
          ) {
            let option =
              this.form.upi.slice(0, this.form.upi.indexOf('@') + 1) + item
            return option
          }
        })
      }
    }
    if (!this.form.upi) {
      return []
    }
  }

  public setInput(mail) {
    this.form.upi = mail
  }

  public blur() {
    setTimeout(() => {
      this.isShow = false
    }, 100)
  }

  // 视频演示播放
  public videoShow: boolean = false
  public videoPlay() {
    this.videoShow = true
  }
  public closePlay() {
    this.videoShow = false
  }

  public showQr: boolean = false
  public retryCount: number = 3
  public videoUrl: any = ''

  public confirm() {
    this.verifyLoading = true
    sessionStorage.setItem('steps', '1')
    Cookies.set('steps', '1')

    this.showQr = true
    this.verifyLoading = false
  }

  public copyOrder() {
    Vue.prototype.$message({
      message: 'Copy successfully',
      type: 'success',
      duration: 1500
    })
  }
  public copyUpi() {
    Vue.prototype.$message({
      message: 'Copy successfully',
      type: 'success',
      duration: 1500
    })
  }

  public cancel() {
    this.$confirm(
      'Cancel will close the current transaction. Do you want to cancel?',
      'Attention Please'
    )
      .then(_ => {
        this.showQr = false
        router.replace({ name: 'shutDown' })
      })
      .catch(_ => {})
  }

  public async bind() {
    ;(this.$refs.form as ElForm).validate(async (valid: boolean) => {
      if (valid) {
        try {
          this.loading = true
          this.verifyLoading = true
          await bindUpi(this.form).then((res: any) => {
            this.showQR = res.upi === this.form.upi
            this.loading = false
            this.verifyLoading = false
            if (this.showQR) {
              window.clearInterval(this.poll)
              window.clearInterval(this.timer)
              this.poll = null
              this.timer = null
              this.loading = true
              this.verifyLoading = false
              this.getCounter()
            }
          })
        } catch (err) {
          // @ts-ignore: Unreachable code error
          this.$confirm(err.response.data.message, 'Attention Please').then(
            () => {
              this.showQr = false
            }
          )
          this.verifyLoading = false
          this.loading = false
        }
      } else {
        return false
      }
    })
  }

  public async getCounter() {
    try {
      await getCounter().then((res: any) => {
        this.orderInfo = res
        this.videoUrl = this.orderInfo.video_url
        if (!AppModule.chatInfo) {
          this.config = res.extra_param.config
          this.configdata = res.extra_param
          AppModule.SetChatInfo(res.extra_param.config)
          AppModule.SetChatInfo(res.extra_param)
        }
        this.qrcode = res.extra_param.qrcode ? res.extra_param.qrcode : ''
        // 取最小时间
        const time = res.extra_param.expire_time
          ? res.extra_param.expire_time > res.expire_time
            ? res.expire_time
            : res.extra_param.expire_time
          : res.expire_time
        // 根据语言获取时区
        const timezone =
          this.$route.query['language'] === 'vi' ||
          this.$route.query['language'] === 'th' ||
          this.$route.query['language'] === 'id'
            ? 'Asia/Bangkok'
            : ''
        this.expireTime = timezone
          ? momentTimezone
              .tz(time * 1000, timezone)
              .format('YYYY-MM-DD HH:mm:ss')
          : moment(time * 1000).format('YYYY-MM-DD HH:mm:ss')
        if (!res.is_expired && res.status === 0) {
          // 订单未过期 且 未支付
          if (!this.poll) {
            // 轮询未开始，则开始轮询
            this.showQR = !!res.extra_param.upi || res.skip_fill_upi
            this.poll = setInterval(() => {
              this.getCounter()
            }, 5000)
            const diffTime = res.current_time * 1000 - Date.now()
            this.timer = window.setInterval(async () => {
              let start = momentTimezone.tz(Date.now() + diffTime, timezone) //获取开始时间
              let end = momentTimezone.tz(time * 1000, timezone) //结束时间
              let diff = end.diff(start) //时间差
              let minutes: any = moment.duration(diff).minutes()
              minutes = minutes < 10 ? '0' + minutes : minutes
              let seconds: any = moment.duration(diff).seconds()
              seconds = seconds < 10 ? '0' + seconds : seconds
              if (diff > 0) {
                this.time = minutes + ':' + seconds
              } else {
                window.clearInterval(this.timer)
                window.clearInterval(this.poll)
                router.replace({ name: 'error' })
              }
            }, 1000)
          }
        } else if (res.is_expired) {
          // 订单已过期
          if (this.poll) {
            // 轮询开启则关闭
            window.clearInterval(this.poll)
            window.clearInterval(this.timer)
          }
          router.replace({ name: 'error' })
        } else if (res.status === 1) {
          // 已支付
          if (this.poll) {
            // 轮询开启则关闭
            window.clearInterval(this.poll)
            window.clearInterval(this.timer)
          }
          router.replace({ name: 'success' })
        }
      })

      this.loading = false
    } catch (e) {
      if (!this.poll) {
        if (this.retryCount-- > 0) {
          this.getCounter()
        } else {
          this.$confirm(`Do you want to try again?`, 'The Request Timeout')
            .then(() => {
              this.retryCount = 3
              this.getCounter()
            })
            .catch(() => {
              router.replace({ name: 'error' })
            })
        }
      }
      throw new Error(`${this.$route.path}Counter接口出错${e}`)
    }
  }

  public utrForm: any = {
    utr: ''
  }

  public submit() {
    if (!this.utrForm.utr || this.utrForm.utr.length < 12) {
      this.$message({
        message: 'Please enter the 12-digit transaction UTR No.',
        type: 'warning'
      })
    } else {
      if (this.loading) {
        this.$message({
          message: 'Order has already bind UTR.',
          type: 'warning'
        })
      } else {
        this.loading = true
        bindUtr(this.utrForm)
          .then(() => {
            this.loading = false
            this.$message({
              message: 'Submitted successfully.',
              type: 'success'
            })
          })
          .catch(() => {
            this.loading = false
          })
      }
    }
  }

  mounted() {
    this.getCounter()
    // this.showQr = sessionStorage.getItem('steps') === '1'
    this.showQr =
      Cookies.get('steps') === '1' || sessionStorage.getItem('steps') === '1'
  }
}
